<template>
    <div>
       <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <div class="d-flex justify-content-between align-items-center">
             <h2> Upcoming Events </h2>
            <ol>
              <li><router-link to="/">Home</router-link></li>
              <li><router-link :to="{name:'magazines'}">Events</router-link></li>
            </ol>
          </div>  
        </div>
      </section>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->            
            <div class="col-lg-8">
                <div >
                <div>
                  <b-row>
                    <b-col sm="12"><b-form-input v-model="SearchBy" placeholder="Search by Event name"></b-form-input></b-col>
                    <!-- <b-col sm="4">
                      <div  class="thira-button" >
                        <router-link to="/share/events">Submit Your Event!</router-link>
                      </div> 
                    </b-col> -->
                  </b-row>    
                </div>                  
                  <b-row class="row no-gutters profile-card" v-for="Data in SearchList" v-bind:key="Data.id">
                    <div class="col-12 Thira profile-card-5">
                      <div>
                        <b-row class="page-title-text mt-4 ml-3" >
                          <h5>{{Data.event_name}}</h5>
                        </b-row>

                        <b-row class="page-tagline ml-1">
                          <p >Organized by {{Data.event_by}} on {{Data.event_start_date}} {{Data.event_month}} {{Data.event_year}} </p>
                        </b-row>

                        <p class="pre-formatted" v-if='Data.event_desc !== ("" || null)' ><span v-html="Data.event_desc"></span></p>
                      </div>
                      <div class="card-img-block img-fluid">
                        <b-img-lazy center v-bind:src="Data.event_logo" alt="Image"></b-img-lazy>
                      </div>
                    </div>
                      </b-row>    
                    </div>                
            </div>
            <!-- Article Right side bar section starts here -->
            <div class="col-lg-4">
              <div class="sidebar">
                <!-- sidebar search box -->
                <SideCatgEvents/>
                <!-- <Side Recent Performers/> -->
                <SideRecentPerformers/>  
                <!-- sidebar Filter By Performers Category-->
                <SideFilterPerformers/>  
              </div>              
            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->        

      <!-- ======= Inject Footer section here  ======= -->
      <Home_footer/>
    
    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import { CardPlugin } from 'bootstrap-vue'

Vue.use(CardPlugin)
Vue.use(Vueaxios,axios)

  export default {
  name:"UpcomingEvents",
  metaInfo: {
    title: "Thiraseela :: ",
    titleTemplate: "%s Upcoming Events | Artists Portfolios | Book Stage Shows | Online Program Booking"
  },      
    data() {
      return {
        RespArray: [],
        SearchBy: "",   
      }
    },
    watch: {
       '$route' (to)
       {
        if(this.$route.params.Maincatg == 'All') {
          this.ReadAllEvents(to.params.Maincatg)
        }
        else {
          this.ReadEventsByCatg(to.params.Maincatg)
        }                 
       }
    },     
    mounted()
    {
      if(this.$route.params.Maincatg == 'All') {
        this.ReadAllEvents()
      } 
      else {
        this.ReadEventsByCatg(this.$route.params.Maincatg)
      }           
    },
    methods: {
      ReadAllEvents(){          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllEvents')
        .then((resp)=>{
        this.RespArray=resp.data.ReadAllEvents;
      })
      },
      ReadEventsByCatg(Maincatg){     
        const REQformData = new FormData();
        REQformData.append('event_Catg', Maincatg);               
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadEventsByCatg', REQformData)
        .then((resp)=>{
        this.RespArray=resp.data.ReadAllEvents;
      })
      },      
    },
    computed: {
    SearchList: function(){
        return this.RespArray.filter((RespArray) => {
        return RespArray.event_name.toLowerCase().match(this.SearchBy.toLowerCase())
      });
    },   
  }
  }
</script>

<style scoped>

.profile-card {
    margin-top:10px;
    box-shadow:0 0 2px rgba(0,0,0,0.63);
    border-radius:5px;
}
.profile-card-5{
    /* box-shadow:0 0 10px rgba(0,0,0,0.63); */
    border-radius:5px;
    height: auto;
    /* height: 450px; */
    /* height: 475px; */
    /* overflow: hidden;     */
}
.profile-card-5 .card-img-block {
    padding: 10px 10px 10px 10px;
}

.profile-card-5 .card-img-block img{
    max-width: 100%; 
    max-height: 800px;
    border-radius:30px;
}
.profile-card-5 h5{
    color:#e75480;
    font-weight:800;
    font-size:16px;
}
.profile-card-5 p{
    font-size:14px;
    font-weight:300;
    padding: 1px 10px;
}

.pre-formatted {
  white-space: pre-line;
  text-align: justify;
  max-height: 300px;
  overflow: scroll;  
}
.page-title-text {
  color:#e75480;
  font-weight:800;
  font-size:16px;
  margin-left:10px;
}

.profile-card-5  .page-tagline  {
    font-style: italic;
    font-size:14px;
    font-weight:300;
    color: #72afce;
}
</style>
